import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LandingBanner from '../../components/LandingBanner/LandingBanner';
import { PropertiesState } from '../../redux/reducers/getProperties/propertiesReducer';
import { RootState } from '../../redux/reducers/root';
import "./Landing.css";
import { EXTERNAL_IN_PROGRESS, EXTERNAL_SUBMITTED, NEW_CREATION, RESET_LOOKUPS } from '../../redux/actionTypes/actionsTypes';
import { getProfileOverview } from '../../redux/actions/profile/profile';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import PropertyObject from '../../types/property/PropertyObject';
import Pagination from '../../components/common/Pagination/Pagination';
import Header from '../../layouts/Header/Header';
import Search from '../../components/common/Search/Search';
import MenuState from '../../types/menu/MenuState';
import MenuItem from '../../types/menu/MenuItem';
import { setActiveMenuItem } from '../../redux/actions/menu/menu';
import Footer from '../../layouts/Footer/Footer';
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { getGlobalSetting } from '../../redux/actions/global/global';
import { getTestRolls } from '../../redux/actions/campaign/landing';
import parseStatus from '../../utils/parseStatus';
import { resetSaveData, resetSaveResult } from '../../redux/actions/saveData/saveData';
import { initialMenuItem } from '../../redux/reducers/getMenu/getMenuReducer';

export default function Landing(): JSX.Element {

    const { t } = useTranslation('landing');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const propertiesState: PropertiesState = useSelector((state: RootState) => state.propertiesReducer.properties);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);

    const [isProfileReqSent, setIsProfileReqSent] = useState(false);
    const [currentProperty, setCurrentProperty] = useState<PropertyObject>();
    const [campaignYear, setCampaignYear] = useState(new Date().getFullYear());

    useEffect(() => {
        if (!globalSettingState.loading && !globalSettingState.success) {
            dispatch(getGlobalSetting());
        }
    }, [dispatch, globalSettingState.loading, globalSettingState.success]);

    useEffect(() => {
        if (globalSettingState.success && profileOverview.found) {
            setCampaignYear(globalSettingState.setting["campaign_year"] as number);
        }
    }, [globalSettingState, profileOverview.found, profileOverview.profile.profile_type_code])

    // Pagination related states and methods
    const [pageData, setPageData] = useState<Array<PropertyObject> | null>(propertiesState.rolls);
    const [totalPages, setTotalPages] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(7);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setPageData(propertiesState.rolls.slice(itemOffset, endOffset));
        setTotalPages(Math.ceil(propertiesState.rolls.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, propertiesState.rolls]);

    useEffect(() => {
        // reset menu status -- start
        if (menuState.menu && menuState.menu.length > 0) {
            const om: MenuItem[] = [];
            const sort = (root: Array<MenuItem> | null | undefined) => {
                if (root && root.length > 0) {
                    for (let i = 0; i < root.length; i++) {
                        if (root[i].componentName) {
                            if (root[i].hasTabs) {
                                const subItems = root[i].subItems;
                                if (subItems) {
                                    for (let j = 0; j < subItems.length; j++) {
                                        om.push({ ...root[i], selectedTabIndex: j });
                                    }
                                }
                                continue;
                            }
                            om.push(root[i]);
                        }
                        sort(root[i].subItems);
                    }
                }
                return;
            };
            sort(menuState.menu);
            dispatch(setActiveMenuItem(om[0]));
        }

        // reset menu status -- end

        if (!propertiesState.found) {
            dispatch(getTestRolls());
        }
        // eslint-disable-next-line        
    }, []);

    useEffect(() => {
        if (isProfileReqSent && !profileOverview.loading && profileOverview.found && globalSettingState.success) {
            setIsProfileReqSent(false);
            if (!profileOverview.profile.current_campaign) {
                toast.error("No campaign found for roll");
            }
            else {
                let path = `/${campaignYear}/${currentProperty?.rollNumber}/${currentProperty?.profileId}/${currentProperty?.profileTypeCode}/`;
                switch (currentProperty?.statusCode as string) {
                    case NEW_CREATION:
                        path += "start";
                        break;
                    case EXTERNAL_IN_PROGRESS:
                        path += "continue";
                        break;
                    case EXTERNAL_SUBMITTED:
                        path += "overview";
                        break;
                    default:
                        path += "overview";
                }
                navigate(path);
                localStorage.setItem('rollNumber', currentProperty?.rollNumber as string);
                localStorage.setItem('profileId', currentProperty?.profileId as string);
                dispatch(resetSaveData());
                dispatch(resetSaveResult());
                dispatch(setActiveMenuItem(initialMenuItem));
            }
        }
        else if (isProfileReqSent && !profileOverview.loading && !profileOverview.found) {
            setIsProfileReqSent(false);
            toast.error("Failed to retrieve profile details");
        }

    }, [profileOverview, isProfileReqSent, campaignYear, navigate, currentProperty, globalSettingState.success, dispatch])

    const chooseProperty = (property: PropertyObject) => {
        setCurrentProperty(property);
        if (profileOverview && profileOverview.profile.profile_type_code !== property.profileTypeCode) {
            dispatch({ type: RESET_LOOKUPS });
        }
        if (property.profileId === "0") {
            toast.error("Invalid profile, please verify details");
        } else {
            dispatch(getProfileOverview(property.profileId));
            setIsProfileReqSent(true);
        }
    }

    const handleItemsPerPage = (itemCount: number) => {
        setItemsPerPage(itemCount);
    }

    const handlePageClick = ({ selected }: { selected: number }) => {
        if (pageData) {
            const newOffset = (selected * itemsPerPage) % propertiesState.rolls.length;
            setItemOffset(newOffset);
        }
    };

    const handlePageData = useCallback((data: PropertyObject[]) => {
        const endOffset = itemOffset + itemsPerPage;
        setPageData(data.slice(itemOffset, endOffset));
        setTotalPages(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const filterRolls = (searchString: string) => {
        if (searchString) {
            const filteredData = propertiesState.rolls.filter(currentProperty => currentProperty.rollNumber.includes(searchString));
            handlePageData(filteredData);
        }
        else {
            handlePageData(propertiesState.rolls);
        }
    }

    useEffect(() => {
        if (propertiesState.found) {
            handlePageData(propertiesState.rolls);
        }
    }, [propertiesState, handlePageData]);

    return (<>
        <Header showAutoSave={false} isInternalUser={false} showRollNumber={false} />
        <LandingBanner />
        <div className='landing-body-container'>
            <h1 className='landing-heading'>
                {
                    `${t("welcome-heading-1")} ${propertiesState.firstName} 
                    ${t("welcome-heading-2")}`

                }
            </h1>
            <div className='landing-body'>
                <div className='search-roll-container'>
                    <span className='select-text'>{t("select-first")}</span>
                    <Search showInputByDefault={false} handleSearch={filterRolls} />
                </div>
                <LoadingSpinner loading={profileOverview.loading || globalSettingState.loading || propertiesState.loading}>
                    <div className="profile-list-container">
                        <table className='profile-table' cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr className='profile-table-heading-container'>
                                    <th>Roll number</th>
                                    <th>Profile Type</th>
                                    <th className='text-right'>Status</th>
                                </tr>
                            </thead>
                            <tbody className='profile-table-body-container'>
                                {pageData && (pageData).map((property, index) => {
                                    return (
                                        <tr key={`property-${index}`} onClick={() => chooseProperty(property)}>
                                            <td>{property.rollNumber}</td>
                                            <td>{property.propertyTypeName}</td>
                                            <td className='text-right'>
                                                <span className={`status`}>
                                                    {property && <>
                                                        {parseStatus(property.statusCode)}
                                                    </>}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            breakLabel='...'
                            nextLabel='Next'
                            previousLabel='Previous'
                            onPageChange={handlePageClick}
                            itemsPerPage={itemsPerPage}
                            totalPages={totalPages}
                            setItemsPerPage={handleItemsPerPage} />
                    </div>
                </LoadingSpinner>
            </div>
        </div>
        <Footer />
    </>);
}
