import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faRoute, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXTERNAL_IN_PROGRESS, EXTERNAL_SUBMITTED, RESET_PROPERTIES, RESET_UPDATE_CAMPAIGN_STATUS } from '../redux/actionTypes/actionsTypes';
import { updateAcknowledgementFlag } from '../redux/actions/campaign/updateAcknowledgement';
import { updateCampaignStatus } from '../redux/actions/campaign/updateStatus';
import { getIncomeFile } from '../redux/actions/file/file';
import { getProfileDetails } from '../redux/actions/profile/profile';
import { setSaveResultSuccess } from '../redux/actions/saveData/saveData';
import { RootState } from '../redux/reducers/root';
import { UpdateCampaignState } from '../redux/reducers/getCampaign/updateCampaignStatusReducer';
import { FileState } from '../redux/reducers/getFile/getFileReducer';
import { GlobalSettingState } from '../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { ProfileDetailsState } from '../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from '../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../redux/reducers/getSaveData/saveDataReducer';
import { UpdateAcknowledgementState } from '../redux/reducers/updateAcknowledgement/updateAcknowledgementReducer';
import { GeneratePDFPayload } from '../types/pdf/GeneratePDFPayload';
import Button from '../components/common/Button/Button';
import LoadingSpinner from '../components/common/LoadingSpinner/LoadingSpinner';
import '../pages/Summary/Summary.css';

export default function Submit(): JSX.Element {
    const { t } = useTranslation('common');
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const updateCampaignStatusState: UpdateCampaignState = useSelector((state: RootState) => state.campaignReducer.updateCampaignStatus);
    const acknowledgementState: UpdateAcknowledgementState = useSelector((state: RootState) => state.acknowledgementReducer.updateAcknowledgementFlag);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const submitDispatched = useRef(false);
    const [downloading, setDownloading] = useState(false);
    const [isCertified, setIsCertified] = useState(profileDetails.data.acknowledgementFlag || false);
    const rollNumber = localStorage.getItem('rollNumber');
    const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file);
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);

    useEffect(() => {
        if (saveData.saving) {
            dispatch(setSaveResultSuccess());
        }
    }, [dispatch, saveData.saving])

    const submit = () => {
        if (!isCertified) {
            toast.error('Please accept the acknowledgement before making a submission');
            return;
        }

        if (!submitDispatched.current && profileOverview.profile.current_campaign.statusCode === EXTERNAL_IN_PROGRESS) {
            dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS });
            dispatch(updateCampaignStatus(EXTERNAL_SUBMITTED,
                String(profileOverview.profile.profile_id),
                localStorage.getItem('campaignId') as string));
            submitDispatched.current = true;
        }
    };

    useEffect(() => {
        if (submitDispatched.current && !updateCampaignStatusState.loading && !updateCampaignStatusState.updated) {
            submitDispatched.current = false;
        }
    }, [updateCampaignStatusState.loading, updateCampaignStatusState.updated]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsCertified(isChecked);
        dispatch(updateAcknowledgementFlag(
            isChecked,
            profileOverview.profile.profile_id,
            localStorage.getItem('campaignId') as string
        ));
    };

    useEffect(() => {
        if (acknowledgementState.success === true) {
            dispatch(getProfileDetails(localStorage.getItem('currentSnapshotId') as string));
        }
    }, [acknowledgementState.success, dispatch, profileOverview.profile]);

    const downloadPreviewFile = () => {
        const payload: GeneratePDFPayload = {
            language: i18n.language,
            rollNumber: rollNumber as string,
            profileId: profileOverview.profile.profile_id,
            campaignId: Number(localStorage.getItem('campaignId') as string),
            campaignYear: profileOverview.profile.current_campaign.year,
            snapshotRequestType: "2"
        }
        console.log("download preview file payload", payload);
        dispatch(getIncomeFile(payload));
        setDownloading(true);
    };

    useEffect(() => {
        if (downloading && fileState.loading === false) {
            setDownloading(false);
            if (fileState.status === 200) {
                const base64string = "data:application/pdf;base64," + fileState.pdf;
                const downloadLink = document.createElement("a");
                downloadLink.href = base64string;
                downloadLink.download = rollNumber + "_" + profileOverview.profile.current_campaign.year + "_PIER.pdf";
                downloadLink.click();
            } else {
                toast.error(fileState.message);
            }
        }
    }, [downloading, fileState, profileOverview.profile.current_campaign.year, rollNumber]);

    return (
            <div className='summary-page-container'>
                {profileOverview.profile.current_campaign.statusCode === EXTERNAL_IN_PROGRESS &&
                    <>
                        <FontAwesomeIcon icon={faRoute} className='print-icon' />
                        <p className='print-wip-text'>{t('submit-page')}{globalSettingState.setting["campaign_year"] as number}</p>
                        <div className="certify">
                            <input
                                type="checkbox"
                                id="certify-checkbox"
                                checked={isCertified}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="certify-checkbox">
                                {t('certify')}
                            </label>
                        </div>
                        <Button type='submit' text={t('submit')} onClick={() => submit()} />
                    </>
                }
                {
                    submitDispatched.current && updateCampaignStatusState.updated &&
                    <div className="modal-bg">
                        <LoadingSpinner loading={fileState.loading}>
                            <div className="modal-container">
                                <div className="modal-header">
                                </div>
                                <div className="modal-body">
                                    <div className='flex flex-column align-items-center'>
                                        <p><FontAwesomeIcon icon={faCircleCheck} color='green' size='3x' /></p>
                                        <p><label>All set for {globalSettingState.setting["campaign_year"] as number}!</label></p>
                                        <span>Income expense return submitted for {globalSettingState.setting["campaign_year"] as number}.</span>
                                        <span>Please keep reference number <b>{updateCampaignStatusState.campaign.campaignId as number}</b> for your record.</span>
                                    </div>
                                    <p></p>
                                    <div className='button-group justify-content-center'>
                                        <Button type='submit' text={t('download-pdf')} onClick={() => downloadPreviewFile()} />
                                        <Button type='secondary' text={t("return")} onClick={() => { dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS }); dispatch({ type: RESET_PROPERTIES }); navigate('/landing'); }} />
                                    </div>
                                    <p></p>
                                </div>
                            </div>
                        </LoadingSpinner>
                    </div>
                }
            </div>
    );
}
