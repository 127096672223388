/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import ProfileDataType from "../../../types/profile/ProfileData";
import { GET_PROFILE_DETAILS, RESET_GET_PROFILE_DETAILS } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface ProfileDetailsState extends BaseAsyncReducerState {
  success: boolean,
  message: string,
  data: ProfileDataType
}

const initialState: ProfileDetailsState = {
  success: false,
  message: "",
  data: {
    tradeName: "",
    contactName: "",
    contactPosition: "",
    phoneNumber: "",
    phoneExtension: "",
    partialIncomeMonth: "",
    email: "",
    fiscalYearEndDate: "",
    ownerOccupiedFlag: null,
    acknowledgementFlag: null,
    agentFlag: null,
    analysisFlag: null,
    analysisReviewedBy: "",
    analysisReviewedDate: "",
    assessmentInformation: {}
  },
  loading: false,
  error: false
}

function getProfileDetailsReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_GET_PROFILE_DETAILS) {
    return initialState;
  } else {
    return defaultAsyncReducer(state, action as AppAction, GET_PROFILE_DETAILS)
  }
}

export default getProfileDetailsReducer;