import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/root";
import { LookupsState } from "../../redux/reducers/getLookups/lookupsReducer";
import { getLookups } from "../../redux/actions/lookups/lookups";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../redux/actions/incomeAttributes/incomeAttributes";
import IncomeAttributeTableFragmentGenerator from "../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { SaveDataState } from "../../redux/reducers/getSaveData/saveDataReducer";
import { IncomeAttributeState } from "../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer";
import "./PIE.css";
import CurrencyInput from "../../components/common/CurrencyInput/CurrencyInput";
import MenuState from "../../types/menu/MenuState";
import { SaveResultState } from "../../redux/reducers/getSaveData/saveResultReducer";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";

function RHOReserve(): ReactElement {
    const { t } = useTranslation(['pie']);
    const dispatch = useDispatch();
    const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const lookupState: LookupsState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
    const lookupType = "rho-income-attributes";
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const activeMenuName = menuState.activeItem.name;
    let total = 0;

    useEffect(() => {
        if ((!incomeAttributesState.loading && !incomeAttributesState.success)
            || incomeAttributesState.snapshotId !== profileOverview.profile.current_campaign.currentSnapshotId
        ) {
            dispatch(getIncomeAttributesBySnapshotId(profileOverview.profile.current_campaign.currentSnapshotId));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (saveData.saving) {
            const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.descriptiion);
            dispatch(putIncomeAttributesBySnapshotId(profileOverview.profile.current_campaign.currentSnapshotId, incomeAttributes));
        }
    }, [dispatch, incomeAttributesState.data, profileOverview.profile.current_campaign.currentSnapshotId, saveData.saving]);

    useEffect(() => {
        if (saveResultState.status === 200) {
          dispatch(getIncomeAttributesBySnapshotId(profileOverview.profile.current_campaign.currentSnapshotId));
        }
      }, [saveResultState, dispatch, profileOverview.profile.current_campaign.currentSnapshotId]);

    useEffect(() => {
        if (!lookupState.success) {
            dispatch(getLookups(lookupType));
        }
    }, [dispatch, lookupState.success, lookupType]);

    const findAttributeData = (attributeCode: string) => {
        let incomeAttributesData: Record<string, unknown> | undefined = undefined;
        if (incomeAttributesState.data) {
            incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
        }
        return incomeAttributesData;
    };

    const handleCurrencyChange = (newAmt: number | undefined, name: string, _oldAmt: number | undefined) => {
        const idx = name.lastIndexOf("-");
        if (idx !== -1) {
            const code = name.substring(idx + 1);
            const incomeAttributesData = findAttributeData(code);
            const na = newAmt === undefined ? null : newAmt;
            if (incomeAttributesData) {
                incomeAttributesData["value"] = na;
            } else {
                (incomeAttributesState.data as Record<string, unknown>[]).push({
                    snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
                    code: code,
                    description: null,
                    value: na,
                    type: "3",
                    percent: null,
                    year: null,
                });
            }
        }
    }

    const handleCommentChange = (value: string, name: string) => {
        const idx = name.lastIndexOf("-");
        if (idx !== -1) {
            const code = name.substring(idx + 1);
            const incomeAttributesData = findAttributeData(code);
            const v = value.trim() === "" ? null : value.trim();
            if (incomeAttributesData) {
                incomeAttributesData["description"] = v;
            } else {
                (incomeAttributesState.data as Record<string, unknown>[]).push({
                    snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
                    code: code,
                    description: v,
                    value: null,
                    type: "3",
                    percent: null,
                    year: null
                });
            }
        }
    }

    return (
        <>
            {
                (!incomeAttributesState.loading && !lookupState.loading) &&
                    <>
                        <table className="table-with-border pie-table capital-expenditures-table">
                            <thead>
                                <tr>
                                    <th colSpan={2} className="text-center" id="capital-expenditure-summary-heading" scope="col">
                                        {t('costs.capitalExpSummary')}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="text-left padding-left-10 padding-right-10" id="expenditure-type-column-heading" scope="col">
                                        {t('costs.expenditureType')}
                                    </th>
                                    <th className="text-left padding-left-10 padding-right-10" id="expenditure-amount-column-heading" scope="col">{t('costs.amount')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(lookupState.lookups[2]['reserve'] && lookupState.lookups[4]['reserve']) && ((lookupState.lookups[2]['reserve'].concat(lookupState.lookups[4]['reserve'])).map((attr, key) => {
                                    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                                    if (incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) {
                                        incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attr.incomeAttributeCode);

                                        if (incomeAttributesData && incomeAttributesData.value
                                            && incomeAttributesData.code !== '50') {
                                            total = total + (incomeAttributesData["value"] as number);
                                          }
                                    }

                                    if(attr.incomeAttributeCode === '50') {
                                        return <>
                                            <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                                            data={incomeAttributesData}
                                            showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                                            handleCommentChange={handleCommentChange}
                                            valueChangeHandler={handleCurrencyChange}
                                            isRequiredField={false}/>

                                            <tr className="sub-th">
                                                <td colSpan={2} className='padding-left-10 padding-right-10' headers="attribute-label"></td>
                                            </tr>
                                        </>
                                    }
                                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                                            data={incomeAttributesData}
                                            showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                                            handleCommentChange={handleCommentChange}
                                            valueChangeHandler={handleCurrencyChange}
                                            isRequiredField={false}
                                            isCapitalCostsOrReplacementReserve={true} />
                                }))}
                                <tr>
                                    <td className='padding-left-10 padding-right-10' headers="attribute-label">{t(activeMenuName + '.Total')}</td>
                                    <td headers="attribute-value"><CurrencyInput value={total ? total : undefined} readOnly={true} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
            }
        </>
    )
}
export default RHOReserve