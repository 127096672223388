import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/common/Button/Button';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import LandingBanner from '../../../components/LandingBanner/LandingBanner';
import { getElsLookups } from '../../../redux/actions/lookups/lookups';
import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer';
import { PropertiesState } from '../../../redux/reducers/getProperties/propertiesReducer';
import { RootState } from '../../../redux/reducers/root';
import "./Continue.css";
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { getProfileDetails } from '../../../redux/actions/profile/profile';
import { toast } from 'react-toastify';
import Header from '../../../layouts/Header/Header';
import { GlobalSettingState } from '../../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import Footer from '../../../layouts/Footer/Footer';
import { setReadOnly } from '../../../redux/actions/readOnly/readOnly';

export default function Continue(): JSX.Element {

    const { t } = useTranslation('transition');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isProfileDetailsReqSent, setIsProfileDetailsReqSent] = useState(false);

    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const propertiesState: PropertiesState = useSelector((state: RootState) => state.propertiesReducer.properties);
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const [campaignYear, setCampaignYear] = useState(new Date().getFullYear());
    const [continuing, setContinuing] = useState(false);

    const rollNumber = window.location.href.split('/')[5];

    useEffect(() => {
        if (globalSettingState.success) {
            setCampaignYear(globalSettingState.setting["campaign_year"] as number);
        }
    }, [globalSettingState])

    const continueReturn = (e: React.MouseEvent) => {
        e.preventDefault();
        if (profileOverview.profile.current_campaign) {
            setIsProfileDetailsReqSent(true);
            dispatch(getProfileDetails(String(profileOverview.profile.current_campaign.currentSnapshotId)));
        }
        setContinuing(true);
        dispatch(setReadOnly(false))

        if (!elsLookupsState.success) {
            dispatch(getElsLookups());
        }
    }

    useEffect(() => {
        if (isProfileDetailsReqSent && !profileDetails.loading && continuing) {
            // Verify status code before proceeding to portal
            if (elsLookupsState.success
                && profileDetails.success 
                // && profileOverview.profile.current_campaign.statusCode === "EXTERNAL_IN_PROGRESS"
            ) {
                localStorage.removeItem('incomeReturnType'); // remove incomeReturnType for WIP as it's irrelevant
                localStorage.setItem('rollNumber', rollNumber)
                localStorage.setItem('profileId', String(profileOverview.profile.profile_id));
                localStorage.setItem('activeCampaignYear', String(profileOverview.profile.current_campaign.year));
                localStorage.setItem('campaignId', String(profileOverview.profile.current_campaign.campaignId));
                localStorage.setItem('currentSnapshotId', String(profileOverview.profile.current_campaign.currentSnapshotId));
                setContinuing(false);
                navigate('/portal')
            } else if (!profileDetails.success) {
                toast.error("Failed to retrieve profile details");
            }
        }
    }, [isProfileDetailsReqSent, profileDetails, elsLookupsState, continuing, navigate, profileOverview, rollNumber]);

    return (<>
        <Header showAutoSave={false} isInternalUser={false} />
        <LandingBanner />
        <LoadingSpinner loading={profileOverview.loading || profileDetails.loading || continuing}>
            <div className='continue-body-container'>
                <div className='continue-body'>
                    {propertiesState.found && <>
                        <h1>{`${t("continue.welcome")} ${propertiesState.firstName} ${propertiesState.lastName}!`}</h1>
                        <p>{`${t("continue.saved")} ${campaignYear}`}</p>
                        <div className="button-group">
                            <Button onClick={e => continueReturn(e)} type="submit" text={t("continue.continue")} />
                            <Button onClick={() => navigate('/landing')} type="secondary" text={t("return")} />
                        </div>
                    </>}
                </div>
            </div>
        </LoadingSpinner>
        <div className="start-footer">
            <Footer />
        </div>
    </>);
}
