import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialPerCrop, OperationFieldProps } from '../../pages/BusinessOperations/BusinessOperations';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";

export default function GEVPerCrop(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const errors = props.errors && props.errors[props.name] ? props.errors[props.name] as Record<string, string> : {}
    const [data, setData] = useState(props.data[props.name] as Record<string, number | string | null>)

    useEffect(() => {
        setData(props.data[props.name] as Record<string, number | string | null>)
    }, [props.data, props.name])

    return (
        <>
            <tr className='gev-input-tr'>
                <td>
                    <div className='gev-input-label'>{t(props.operation + "." + props.name)}</div>
                    <div className='gev-space-between padding-bottom-percrop'>
                        {
                            Object.keys(initialPerCrop).map((option) => {
                                return (
                                    <div key={option} className='flex flex-column align-item-start'>
                                        <div>{t(props.operation + "." + props.name + "Options." + option)}</div>
                                        <input
                                            id={props.operation + "." + props.name + "." + option}
                                            name={props.operation + "." + props.name + "." + option}
                                            value={data[option] === null ? "" : data[option]}
                                            title={t(props.operation + "." + props.name + "Options." + option)}
                                            disabled={isReadOnly}
                                            type="number"
                                            autoComplete="off"
                                            aria-label={t(props.operation + "." + props.name + "Options." + option)}
                                            onChange={() => {
                                                //
                                            }}
                                            onKeyDown={(e) => {
                                                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                                            }}
                                            onWheel={(e) => {
                                                if (props.type === "number") {
                                                    e.currentTarget.blur()
                                                }
                                            }}
                                        />
                                        <div className="input-error-message">
                                            {errors[option]}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='flex flex-column align-item-start'>
                        <div>{t(props.operation + "." + props.name + "Options.other")}</div>
                        <input type="text"
                            id={props.name + ".other"}
                            name={props.name + ".other"}
                            value={(data["other"] === null) ? "" : data["other"]}
                            title={t(props.operation + "." + props.name + "Options.other")}
                            aria-label={t(props.operation + "." + props.name + "Options.other")}
                            autoComplete="off"
                            onChange={() => {
                                //
                            }}
                        />
                        <div className="input-error-message">
                            {errors["other"]}
                        </div>
                    </div>
                    <div className="input-error-message">
                        {errors["options"]}
                    </div>
                </td>
            </tr >
        </>
    )
}
