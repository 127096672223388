/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/common/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import Asterisk from '../../../components/common/Asterisk/Asterisk';
import Dropdown, { Option } from '../../../components/common/Dropdown/Dropdown';
import Input from '../../../components/common/Input/Input';
import Button from '../../../components/common/Button/Button';
import RadioButton from '../../../components/common/RadioGroup/RadioButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faLock, faLockOpen, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { getElsLookups } from '../../../redux/actions/lookups/lookups';
import { toast } from 'react-toastify';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import "./SuiteBreakdown.css";
import { deleteIncomeUnitById, getApartmentTenantSummaryBySnapshotId, postIncomeUnit, postMultipleIncomeUnits, putIncomeUnit, resetDeleteIncomeUnitById, resetPostIncomeUnit, resetPutIncomeUnit } from '../../../redux/actions/incomeUnit/incomeUnit';
import { BaseAsyncReducerState } from '../../../redux/types';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { setSaveResultSuccess } from '../../../redux/actions/saveData/saveData';
import { updateUnitState } from '../../../redux/reducers/getIncomeUnit/updateUnitReducer';
import { createUnitState } from '../../../redux/reducers/getIncomeUnit/createUnitReducer';
import Modal from '../../../components/common/Modal/Modal';
import SuiteBreakdownGuidedData from '../../../types/suiteBreakdown/SuiteBreakdownGuidedData';
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow';
import { useReadOnly } from '../../../utils/ReadOnlyContext';
interface SuiteBreakdownProps {
    tableData?: Record<string, unknown>[]  // For testing purpose, takes in a hard coded data array
}

export default function SuiteBreakdown(props: SuiteBreakdownProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    const initialGuidedStyleData: SuiteBreakdownGuidedData = {
        apartment: 0,
        house: 0,
        row: 0
    }

    const [unitData, setUnitData] = useState<Record<string, unknown>[]>(props.tableData ?? []);
    const [isToastVisible, setisToastVisible] = useState(false);
    const [editableUnitIndex, setEditableUnitIndex] = useState<number | null>(null);
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [hasRowBeenChanged, setHasRowBeenChanged] = useState(false)
    const [rentedToTenants, setRentedToTenants] = useState(true);
    const [modifiedIdxs, setModifiedIdxs] = useState<number[]>([])
    const { t } = useTranslation(["suite_breakdown_table", "common"]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const apartmentTenantSummaryState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeUnitReducer.getApartmentTenantSummary);
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const updateUnitState: updateUnitState = useSelector((state: RootState) => state.incomeUnitReducer.putIncomeUnit);
    const createUnitState: createUnitState = useSelector((state: RootState) => state.incomeUnitReducer.postIncomeUnit);
    const deleteIncomeUnitState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeUnitReducer.deleteIncomeUnit);
    const isModifiedSubmission = localStorage.getItem("isInternalUser") && localStorage.getItem("editMode");

    const [styleOptions, setStyleOptions] = useState<Option[]>([]);
    const [unitSubtype3Options, setUnitSubtype3Options] = useState<Option[]>([]);
    const [tenancyTypeOptions, setTenancyTypeOptions] = useState<Option[]>([]);
    const [showGuidedModal, setShowGuidedModal] = useState(true)
    const [forceShowGuidedModal, setForceShowGuidedModal] = useState(false)

    const [guidedStyleData, setGuidedStyleData] = useState<SuiteBreakdownGuidedData>(initialGuidedStyleData as SuiteBreakdownGuidedData)
    const dispatch = useDispatch();


    const guidedStyleApartment: TableCellData[] = [
        { name: "apartment", type: "integer", header: "num-units-header", forced: true, positiveOnly: true },
    ]

    const guidedStyleHouse: TableCellData[] = [
        { name: "house", type: "integer", header: "num-units-header", forced: true, positiveOnly: true },
    ]

    const guidedStyleRow: TableCellData[] = [
        { name: "row", type: "integer", header: "num-units-header", forced: true, positiveOnly: true },
    ]


    const sortByTenantId = (array: Record<string, unknown>[]): Record<string, unknown>[] => {
        return array.sort((a, b) => {
            const tenantIdA = a.tenantId as number;
            const tenantIdB = b.tenantId as number;

            if (tenantIdA === undefined && tenantIdB !== undefined) {
                return -1;
            }

            if (tenantIdA !== undefined && tenantIdB === undefined) {
                return 1;
            }

            if (tenantIdA === undefined && tenantIdB === undefined) {
                return 0;
            }

            if (tenantIdA > tenantIdB) {
                return -1
            }

            if (tenantIdA < tenantIdB) {
                return 1
            }
            return 0;
        })
    }

    useEffect(() => {
        if (deleteIncomeUnitState.success) {
            dispatch(resetDeleteIncomeUnitById());
            setIsRequestSent(false);
            setEditableUnitIndex(null);
            toast.success(t("data-deleted") as string);
        }
    }, [deleteIncomeUnitState.success, dispatch, t]);
    

    useEffect(() => {
        if (updateUnitState.success) {
            setIsRequestSent(false);
            setEditableUnitIndex(null);
            toast.success(t("data-saved") as string)
            dispatch(resetPutIncomeUnit())
        }
    }, [updateUnitState, dispatch, t])

    useEffect(() => {
        const replaceUnitWithPostResponse = (unit: Record<string, unknown>) => {
            const dataSet = [...unitData];
            const index = modifiedIdxs[0];
            dataSet[index] = unit;
            setUnitData(dataSet);
            setModifiedIdxs([]);
        };
        


        if (createUnitState.success) {
            const created = createUnitState.data as Record<string, unknown>;
            dispatch(resetPostIncomeUnit())
            replaceUnitWithPostResponse(created)
            setIsRequestSent(false);
            setEditableUnitIndex(null);
            toast.success(t("data-created") as string)
        }
    }, [createUnitState, dispatch, unitData, t, modifiedIdxs])

    useEffect(() => {
        if (saveData.saving) {                
            if (!updateUnitState.loading && !createUnitState.loading && !deleteIncomeUnitState.loading) {
                dispatch(setSaveResultSuccess());
            }
        }
    }, [dispatch, saveData.changesPending, saveData.saving, updateUnitState.loading, createUnitState.loading, deleteIncomeUnitState.loading])

    useEffect(() => {
        if (!isRequestSent) {
            dispatch(getApartmentTenantSummaryBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
            setIsRequestSent(true);
        }
    }, [dispatch, isRequestSent]);

    useEffect(() => {
        if (!apartmentTenantSummaryState.loading) {
            console.log('ast: '+JSON.stringify(apartmentTenantSummaryState));
            if (apartmentTenantSummaryState.success) {
                console.log('good')
                const respData = apartmentTenantSummaryState.data as Record<string, unknown>[]
                setUnitData(sortByTenantId(respData))
            } else if (apartmentTenantSummaryState.error && !isToastVisible) {
                console.log('bad')
                toast.warn(t("noDataFound") as string);
                setisToastVisible(true)
            }
        }
    }, [t, apartmentTenantSummaryState, isToastVisible]);

    useEffect(() => {
        if (elsLookupsState.success) {
            const sortedUnitStyleTypeDropdown =
                (elsLookupsState.dropdowns["unitStyleType"] as Record<string, unknown>[]).sort((a, b) =>
                    ((a["unitStylePosition"] as number) > (b["unitStylePosition"] as number)) ? 1 :
                        (((b["unitStylePosition"] as number) > (a["unitStylePosition"] as number)) ? -1 : 0));
            const sortedUnitStyleTypeOptions: Option[] = [{ value: "", text: "" }];
            sortedUnitStyleTypeDropdown.forEach((option) => {
                if ((option["activeFlag"] as string) === "Y") {
                    sortedUnitStyleTypeOptions.push({ value: option["unitStyleCode"] as string, text: option["unitStyleDescription"] as string });
                }
            });
            setStyleOptions(sortedUnitStyleTypeOptions);

            const sortedUnitsubtype3Dropdown =
                (elsLookupsState.dropdowns["unitSubType3"] as Record<string, unknown>[]).sort((a, b) =>
                    ((a["unitSubtypePosition"] as number) > (b["unitSubtypePosition"] as number)) ? 1 :
                        (((b["unitSubtypePosition"] as number) > (a["unitSubtypePosition"] as number)) ? -1 : 0));
            const sortedUnitsubtype3Options: Option[] = [{ value: "", text: "" }];
            sortedUnitsubtype3Dropdown.forEach((option) => {
                if ((option["activeFlag"] as string) === "Y") {
                    sortedUnitsubtype3Options.push({ value: option["unitSubtypeCode"] as string, text: option["unitSubtypeDescription"] as string });
                }
            });
            setUnitSubtype3Options(sortedUnitsubtype3Options);

            const ttOptions: Option[] = [{ value: "", text: "" }];
            (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[]).forEach((option) => {
                if ((option["activeFlag"] as string) === "Y") {
                    ttOptions.push({ value: option["tenancyTypeCode"] as string, text: option["tenancyTypeDescription"] as string });
                }
            });

            setTenancyTypeOptions(ttOptions);
        } else if (!elsLookupsState.success && !elsLookupsState.loading) {
            dispatch(getElsLookups());
        }
    }, [elsLookupsState, dispatch])

    const mapDesignTypeByTT = (tenancyTypeCode: string | null) => {
        const mappedOptions: Option[] = [];
        if (tenancyTypeCode) {
            const dtMappedBytt = (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[])
                .filter(tt => tt.tenancyTypeCode === tenancyTypeCode)[0]["unitDesignTypeCode"] as string[];

            const dt = (elsLookupsState.dropdowns["designType"] as Record<string, unknown>[])
                .filter((dt) => dtMappedBytt.includes(String(dt.unitDesignTypeCode)));

            dt.forEach((option) => {
                if ((option["activeFlag"] as string) === "Y") {
                    mappedOptions.push({ value: option["unitDesignTypeCode"] as string, text: option["unitDesignTypeDescription"] as string });
                }
            })
        }

        return mappedOptions;
    }

    let headings = [
        <th key="column-1-header" title={t("type-tooltip")}>{t("type")} <Asterisk /></th>,
        <th key="column-2-header" title={t("style-tooltip")}>{t("style")} <Asterisk /></th>,
        <th key="column-3-header" title={t("baths-tooltip")}>{t("baths")} <Asterisk /></th>,
        <th key="column-4-header" title={t("size-tooltip")}>{t("size")}</th>,
        <th key="column-5-header" title={t("rent-tooltip")}>{t("rent")} <Asterisk /></th>,
        <th key="column-6-header"></th>,
        <th key="column-7-header"></th>
    ]

    if (isModifiedSubmission) {
        headings = [
            <th key="column-9-header">BIN</th>,
            <th key="column-10-header">Tenancy Type</th>,
            <th key="column-11-header">Design Type</th>,
            <th key="column-12-header">Analysis</th>,
            <th key="column-13-header">Priority</th>,
            <th key="column-14-header">Link</th>,
            <th key="column-1-header" title={t("type-tooltip")}>{t("type")} <Asterisk /></th>,
            <th key="column-2-header" title={t("style-tooltip")}>{t("style")} <Asterisk /></th>,
            <th key="column-3-header" title={t("baths-tooltip")}>{t("baths")} <Asterisk /></th>,
            <th key="column-4-header" title={t("size-tooltip")}>{t("size")}</th>,
            <th key="column-5-header" title={t("rent-tooltip")}>{t("rent")} <Asterisk /></th>,
            <th key="column-6-header"></th>,
            <th key="column-7-header"></th>
        ]
    }
    const guidedHeadings = [
        <th id="style-header" key="column-1-header" title="Style">Style</th>,
        <th id="num-units-header" key="column-2-header" title="Number of Units">Number of Units</th>
    ]

    const bathsOptions = [
        { value: "", text: "" },
        { value: 1, text: "1" },
        { value: 1.5, text: "1.5" },
        { value: 2, text: "2" },
        { value: 2.5, text: "2.5" },
        { value: 3, text: "3" },
        { value: 3.5, text: "3.5" },
        { value: 4, text: "4" },
        { value: 4.5, text: "4.5" },
        { value: 5, text: "5" }
    ];

    const analysisOptions = [
        { value: "", text: "" },
        { value: "Y", text: "Y" },
        { value: "N", text: "N" },
        { value: "X", text: "X" },
        { value: "F", text: "F" }
    ]

    const formatOutput = (value: string | number | null | undefined) => {
        if (value === null || value === undefined) {
            return "";
        } else {
            return value.toString();
        }
    }

    const updateValue = (e: React.ChangeEvent, index: number, key: string, isNumber?: boolean) => {
        const dataSet = [...unitData];
        const value = (e.target as HTMLSelectElement | HTMLInputElement).value;
        let errorMessage = '';
        let hideErrorCross = false;
        let isError = false;
        if (isNumber && value !== "") {
            const numericValue = Number(value);
            if (numericValue < 0 && !isNaN(numericValue)) {
                errorMessage = 'Value must be positive';
                hideErrorCross = true;
                isError = true;
                dataSet[index][key] = numericValue;
            }
            else {
                dataSet[index][key] = value;
            }
        } else {
            dataSet[index][key] = value;
        }
        dataSet[index][`${key}_error`] = { errorMessage, hideErrorCross, isError };
        setUnitData(dataSet);
        setHasRowBeenChanged(true)
    }

    const addUnit = (e: React.MouseEvent) => {
        e.preventDefault();
    
        const newUnit = {
            unitTypeCode: '3',
            unitSubtypeCode: '',
            styleTypeCode: '',
            bathCount: '',
            netLeasableArea: '',
            monthlyMarketRent: '',
            new: true
        }

        const updatedData = [newUnit, ...unitData]
        setUnitData(updatedData);
        setEditableUnitIndex(0);
    };

    const removeUnit = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        const dataSet = [...unitData];
        const unit = dataSet[index]
        if (unit['tenantId'] !== undefined) {
            const tenantId = unit['tenantId'] as string
            dispatch(deleteIncomeUnitById(Number(localStorage.getItem('currentSnapshotId') as string), tenantId))

        } else {
            dataSet.splice(index, 1);
            setUnitData(dataSet);
            setEditableUnitIndex(null);
        }

    }

    const toggleLocked = (e: React.MouseEvent, index: number) => {
        e.preventDefault();

        const unit = unitData[index];
        
        // Check if the required fields are filled
        if (!validUnit(unit)) {
            return;
        }
        
        // Toggle lock/unlock state
        if (editableUnitIndex === index) {
            saveRow(index);
            setEditableUnitIndex(null);
        } else if (editableUnitIndex === null) {
            setEditableUnitIndex(index);
        }
    };
    

    const saveRow = (index: number) => {
        if (!hasRowBeenChanged && !unitData[index].new) {
            setEditableUnitIndex(null);
            return;
        }
    
        const unit = unitData[index];
        if (validUnit(unit)) {
            if (isNewUnit(unit)) {
                setModifiedIdxs([index]);
                dispatch(postIncomeUnit(profileOverview.profile.current_campaign.currentSnapshotId, unit));
            } else {
                dispatch(putIncomeUnit(profileOverview.profile.current_campaign.currentSnapshotId, unit));
            }
            setEditableUnitIndex(null);
            setHasRowBeenChanged(false);
        }
    };

    const isNewUnit = (unit: Record<string, unknown>) => {
        return 'new' in unit;
    }

    const requiredFields = (unit: Record<string, unknown>) => {
        let result = true;
        if (unit['unitSubtypeCode'] === '') {
            result = false;
        } else if (unit['styleTypeCode'] === '') {
            result = false;
        } else if (unit['bathCount'] === '') {
            result = false;
        } else if (unit['monthlyMarketRent'] === '') {
            result = false;
        }

        return result;
    }

    const positiveNumbers = (unit: Record<string, unknown>) => {
        let result = true;
        if (parseInt(unit['monthlyMarketRent'] as string) < 0) {
            result = false;
        } else if (parseInt(unit['netLeasableArea'] as string) < 0) {
            result = false;
        }

        return result;
    }

    const validUnit = (unit: Record<string, unknown>) => {
        if (localStorage.getItem("isInternalUser")) {
            //
        } else {
            if (!requiredFields(unit)) {
                toast.error(t("validation-error") as string)
                return false;
            }
        }

        if (!positiveNumbers(unit)) {
            toast.error(t("validation-error-negative") as string)
            return false;
        }

        return true;
    }

    const hasUnsavedRow = () => {
        return editableUnitIndex !== null;
    };    

    const buildTableContent = (): JSX.Element => {
        const tableBody: JSX.Element[] = [];
        for (let index = numberPerPage * (currentPage - 1); index < Math.min(unitData.length, numberPerPage * currentPage); index++) {
            const isLocked = editableUnitIndex !== index;
            console.log('isLocked: '+isLocked);
            console.log('classname: '+(editableUnitIndex > 0 && isLocked ? "trash-off" : "trash"));
            tableBody.push (<tr key={`table-row-${index}`}>
                {isModifiedSubmission && <>
                    <td>
                        <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`bin-${index}`}
                            id={`bin-${index}`} type="text" value={formatOutput(unitData[index].bin as number | null)} onChange={e => updateValue(e, index, "bin", true)} ariaLabel={`bin-${index}`} />
                    </td>
                    <td>
                        <Dropdown readOnly={isLocked} options={tenancyTypeOptions} value={formatOutput(unitData[index].tenancyTypeCode as string | null)}
                            onChange={e => updateValue(e, index, "tenancyTypeCode")} ariaLabel={`tt-${index}`} />
                    </td>
                    <td>
                        <Dropdown readOnly={isLocked} options={mapDesignTypeByTT(unitData[index].tenancyTypeCode as string | null)} value={formatOutput(unitData[index].designTypeCode as string | null)}
                            onChange={e => updateValue(e, index, "designTypeCode")} ariaLabel={`dt-${index}`} />
                    </td>
                    <td>
                        <Dropdown readOnly={isLocked} options={analysisOptions} value={formatOutput(unitData[index].analysisFlag as string | null)}
                            onChange={e => updateValue(e, index, "analysisFlag")} ariaLabel={`analysis-${index}`} />
                    </td>
                    <td>
                        <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`priority-${index}`}
                            id={`priority-${index}`} type="text" value={formatOutput(unitData[index].priority as number | null)} onChange={e => updateValue(e, index, "priority", true)} ariaLabel={`priority-${index}`} />
                    </td>
                    <td>
                        <Input disabled={isLocked} className={isLocked ? 'locked-input' : ''} name={`link-${index}`}
                            id={`link-${index}`} type="checkbox" value={unitData[index].link as string} onChange={e => updateValue(e, index, "link", true)} ariaLabel={`link-${index}`} />
                    </td>
                </>}
                <td title={t("type")}>
                    <Dropdown readOnly={isLocked} options={unitSubtype3Options} value={formatOutput(unitData[index].unitSubtypeCode as string | null)} onChange={e => updateValue(e, index, "unitSubtypeCode")} ariaLabel={t("type") + " " + index} />
                </td>
                <td title={t("style")}>
                    <Dropdown readOnly={isLocked} options={styleOptions} value={formatOutput(unitData[index].styleTypeCode as string | null)} onChange={e => updateValue(e, index, "styleTypeCode")} ariaLabel={t("style") + " " + index} />
                </td>
                <td title={t("baths")}>
                    <Dropdown readOnly={isLocked} options={bathsOptions} value={formatOutput(unitData[index].bathCount as number | null)} onChange={e => updateValue(e, index, "bathCount", true)} ariaLabel={t("baths") + " " + index} />
                </td>
                <td title={t("size")} className={unitData[index]['netLeasableArea_error']?.isError ? 'error-cell' : ''}>
                    <Input readOnly={isLocked} className={!isLocked && unitData[index]['netLeasableArea_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')} name={`unit-size-${index}`} id={`unit-size-${index}`} type="text" value={formatOutput(unitData[index].netLeasableArea as number | null)} onChange={e => updateValue(e, index, "netLeasableArea", true)} ariaLabel={t("size") + " " + index} positiveOnly={true} errorMessage={unitData[index]['netLeasableArea_error']?.errorMessage} hideErrorCross={unitData[index]['netLeasableArea_error']?.hideErrorCross}/>
                </td>
                <td title={t("rent")} className={unitData[index]['monthlyMarketRent_error']?.isError ? 'error-cell' : ''}> 
                    <Input readOnly={isLocked} name={`rent-${index}`} className={!isLocked && unitData[index]['monthlyMarketRent_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')} id={`rent-${index}`} type="text" value={formatOutput(unitData[index].monthlyMarketRent as number | null)} onChange={e => updateValue(e, index, "monthlyMarketRent", true)} ariaLabel={t("rent") + " " + index} positiveOnly={true} errorMessage={unitData[index]['monthlyMarketRent_error']?.errorMessage} hideErrorCross={unitData[index]['monthlyMarketRent_error']?.hideErrorCross}/>
                </td>
                <td>
                    <Button onClick={(e) => toggleLocked(e, index)} text={<FontAwesomeIcon icon={editableUnitIndex === index ? faLockOpen : faLock} className={editableUnitIndex === null || editableUnitIndex === index ? "trash" : "trash-off"} />} ariaLabel={"lock " + index} />
                </td>
                <td>
                    <Button onClick={(e) => removeUnit(e, index)} text={<FontAwesomeIcon icon={faTrashCan} className={editableUnitIndex === null || editableUnitIndex === index ? "trash" : "trash-off"} />} ariaLabel={t("remove") + " " + index} disabled={editableUnitIndex !== null && editableUnitIndex !== index} />
                </td>
            </tr>)
        }
        return (<>{tableBody}</>);
    }

    const handleGuidedChange = (name: keyof SuiteBreakdownGuidedData, value: string | boolean | number | null) => {
        setGuidedStyleData(oldGuidedStyleData => ({
            ...oldGuidedStyleData,
            [name]: value
        }))
    }

    const generateGuidedUnits = () => {
        const generated: Record<string, unknown>[] = []

        for (const [unitType, amt] of Object.entries(guidedStyleData)) {
            if (amt !== null && amt > 0) {
                styleOptions.forEach(option => {
                    if (option.text.toLowerCase() === unitType.toLowerCase()) {
                        for (let i = 0; i < amt; i++) {
                            const newUnit = {
                                unitTypeCode: '3',
                                unitSubtypeCode: '301',
                                styleTypeCode: option.value,
                                bathCount: 1,
                                netLeasableArea: '',
                                monthlyMarketRent: 0,
                                new: true
                            }
                            generated.push(newUnit)
                        }
                    }
                })
            }
        }


        if (editableUnitIndex !== null) {
            setEditableUnitIndex(editableUnitIndex + generated.length);
        }

        setUnitData([...generated, ...unitData])
        setEditableUnitIndex(null);
        setModifiedIdxs([])
        setShowGuidedModal(false)
        setForceShowGuidedModal(false)
        dispatch(postMultipleIncomeUnits(Number(localStorage.getItem('currentSnapshotId') as string), generated, 'a'))
    }

    const addGuidedUnits = () => {
        if (elsLookupsState.success && Object.values(guidedStyleData).some((value) => value !== null && value > 0)) {
            generateGuidedUnits()
        }
    }

    return (
        <div className='suite-breakdown-container'>
            <div className='suite-breakdown-rent-to-tenant'>
                <p>{t("tenants") as string}</p>
                <RadioButton name="rent-to-tenant-radio-yes" id="rent-to-tenant-radio-yes" text={t("confirm") as string} textPosition="left" value="true" onChange={() => setRentedToTenants(true)} currentValue={rentedToTenants} />
                <RadioButton name="rent-to-tenant-radio-no" id="rent-to-tenant-radio-no" text={t("cancel") as string} textPosition="left" value="false" onChange={() => setRentedToTenants(false)} currentValue={rentedToTenants} />
            </div>
            {!isReadOnly && isRequestSent && ((!apartmentTenantSummaryState.loading && unitData.length === 0 && showGuidedModal) || forceShowGuidedModal) ?
                <Modal title="Guided Experience Wizard" onCloseModal={() => {
                    setShowGuidedModal(false)
                    setForceShowGuidedModal(false)
                }}>
                    <div className='guided-modal-container'>
                        <p className='guided-modal-text'>{t('addUnitDesc')}</p>
                        <DataTable headings={guidedHeadings}>
                            <>
                                <TableRow rowLabel='Apartment' cellData={guidedStyleApartment} dataType={guidedStyleData} onChange={handleGuidedChange}></TableRow>
                                <TableRow rowLabel='House' cellData={guidedStyleHouse} dataType={guidedStyleData} onChange={handleGuidedChange}></TableRow>
                                <TableRow rowLabel='Row' cellData={guidedStyleRow} dataType={guidedStyleData} onChange={handleGuidedChange}></TableRow>
                            </>
                        </DataTable>
                        <div className='guided-modal-button-container'>
                            <Button type="submit" text="ADD UNIT(S)" onClick={(() => addGuidedUnits())}></Button>
                            <Button type="cancel" text="CANCEL" onClick={() => {
                                setShowGuidedModal(false)
                                setForceShowGuidedModal(false)
                            }}></Button>
                        </div>

                    </div>
                </Modal>

                :
                rentedToTenants && (<span className='breakdown-table-section'>
                    <div className='suite-breakdown-header-row'>
                        <p className='imp-note'>{t("mandatory-fields", { ns: 'common' })}</p>
                        <div className='suite-control-group'>
                            <div className="unit-table-control-group">
                                {!isReadOnly && <Button onClick={() => {
                                    setGuidedStyleData({
                                        apartment: 0,
                                        house: 0,
                                        row: 0
                                    })
                                    setForceShowGuidedModal(true)
                                }} text={<FontAwesomeIcon
                                    icon={faWandMagicSparkles}
                                    style={{ color: editableUnitIndex !== null ? 'grey' : 'inherit' }}
                                  />} ariaLabel="guidedExperienceWizard"
                                  disabled={editableUnitIndex !== null}
                                  className={editableUnitIndex !== null ? 'disabled-button' : ''} />}
                            </div>
                            {!isReadOnly && <Button disabled={hasUnsavedRow()} type={hasUnsavedRow() ? "disabled" : "submit"} onClick={e => addUnit(e)} text={t("add") as string} />}
                        </div>
                    </div>

                    {(apartmentTenantSummaryState.loading || updateUnitState.loading || createUnitState.loading || deleteIncomeUnitState.loading || (unitData && unitData.length > 0)) &&
                        <DataTable headings={headings}
                            className="suite-breakdown-table"
                            totalPages={Math.ceil(unitData.length / numberPerPage)}
                            currentPage={currentPage} changePage={setCurrentPage}
                            numberPerPage={numberPerPage}
                            changeNumberPerPage={setNumberPerPage}>{buildTableContent()}
                        </DataTable>}
                </span>)
            }
        </div>
    );
}