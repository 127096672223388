/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../components/common/DatePicker/DatePicker'
import { format, parseISO } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Input from '../../../components/common/Input/Input';
import Asterisk from '../../../components/common/Asterisk/Asterisk';
import RadioButton from '../../../components/common/RadioGroup/RadioButton';
import Dropdown from '../../../components/common/Dropdown/Dropdown';
import "./ContactInfo.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import ProfileDataType from '../../../types/profile/ProfileData';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { resetSaving } from '../../../redux/actions/saveData/saveData';
import { getProfileDetails, putProfileDetails, resetPutProfileDetails } from '../../../redux/actions/profile/profile';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import { setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { initialMenuItem } from '../../../redux/reducers/getMenu/getMenuReducer';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';

export default function ContactInfo(): JSX.Element {
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const { t } = useTranslation('contact_info');  
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const profileType = profileOverview.profile.profile_type_code;
    const isInternalUser = localStorage.getItem('isInternalUser') ? true : false;
    const [submitAttempted, setSubmitAttempted] = useState(false);

    const [ownerOccupiedError, setOwnerOccupiedError] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    // eslint-disable-next-line
    const [fiscalYearEndDateError, setFiscalYearEndDateError] = useState("");
    const [agentFlagError, setAgentFlagError] = useState("");

    const activeCampaignYear = Number(localStorage.getItem("activeCampaignYear"))
    const initialAssessmentInformation: Record<string, Record<string, unknown>> = {
        "commercialGrainElevatorFacility": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "totalBuschelCapacity": null,
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "feedMillingProcessingFacility": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "isCommercialGrainElevatorOperated": null,
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "otherAgriBusiness": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "totalBuschelCapacity": null,
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "isCommercialGrainElevatorOperated": null,
            "primaryBusinessOperations": null,
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "farmingOperationCommercialGrain": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "totalBuschelCapacity": null,
            "totalAcresCropped": null,
            "acresPerCrop": {
                "corn": null,
                "soybeans": null,
                "wheat": null,
                "other": null,
            },
            "yieldPerCrop": {
                "corn": null,
                "soybeans": null,
                "wheat": null,
                "other": null,
            },
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "percentageOfThroughput": {
                "campaignYearOne": {
                    "yearValue": activeCampaignYear - 3,
                    "owner": null,
                    "nonOwner": null,
                    "totalThroughput": null
                },
                "campaignYearTwo": {
                    "yearValue": activeCampaignYear - 2,
                    "owner": null,
                    "nonOwner": null,
                    "totalThroughput": null
                },
                "campaignYearThree": {
                    "yearValue": activeCampaignYear - 1,
                    "owner": null,
                    "nonOwner": null,
                    "totalThroughput": null
                }
            },
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "noFormSelected": {
            "selected": false,
            "changesToProperty": null,
            "dateOfChange": null,
        },
    }

    const [isDataValid, setIsDataValid] = useState(true);
    const [profileData, setProfileData] = useState<ProfileDataType>({
        tradeName: "",
        contactName: "",
        contactPosition: "",
        phoneNumber: "",
        phoneExtension: "",
        partialIncomeMonth: "",
        email: "",
        fiscalYearEndDate: "",
        ownerOccupiedFlag: null,
        acknowledgementFlag: null,
        agentFlag: null,
        analysisFlag: null,
        analysisReviewedBy: "",
        analysisReviewedDate: "",
        assessmentInformation: initialAssessmentInformation
    });

    useEffect(() => {
        if (!profileDetails.loading && !profileDetails.success) {
            dispatch(getProfileDetails(localStorage.getItem('currentSnapshotId') as string));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(profileDetails.data && !profileDetails.loading) {
            setProfileData(profileDetails.data);
            // if (!saveData.changesPending && ([profileDetails.data.ownerOccupiedFlag, profileDetails.data.agentFlag].includes(null) ||
            //     [profileDetails.data.fiscalYearEndDate, profileDetails.data.contactName, profileDetails.data.phoneNumber, profileDetails.data.email].includes(""))) {
            //         dispatch(setChangesMade());
            // }
        }
        // eslint-disable-next-line
    }, [profileDetails.data, profileDetails.loading, dispatch]);

    useEffect(() => {
        if (saveData.saving && formRef.current) {
            (formRef.current as HTMLFormElement).requestSubmit();
        }
    }, [saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileDetails(localStorage.getItem('currentSnapshotId') as string))
        }
    }, [saveResultState, profileOverview.profile, dispatch]);

    const analysisOptions = [
        {value: "", text: ""},
        {value: "Y", text: "Y"},
        {value: "N", text: "N"},
        {value: "X", text: "X"},
        {value: "F", text: "F"}
    ]

    const dropdownOptions = [
        {value: "", text: ""},
        {value: "1", text: "1"},
        {value: "2", text: "2"},
        {value: "3", text: "3"},
        {value: "4", text: "4"},
        {value: "5", text: "5"},
        {value: "6", text: "6"},
        {value: "7", text: "7"},
        {value: "8", text: "8"},
        {value: "9", text: "9"},
        {value: "10", text: "10"},
        {value: "11", text: "11"}
    ]

    const onFormChange = (e: React.FormEvent<HTMLFormElement>) => {
        const target = e.target as HTMLInputElement | HTMLSelectElement;
        target.value = target.value.trim();
        const targetName = target.name as string;
        if (targetName === "agentFlag") {
            setProfileData({...profileData, [targetName]: (target.value === "true" ? true : (target.value === "false" ? false : null))});
        } else if (targetName === "ownerOccupiedFlag"){
            setProfileData({...profileData, [targetName]: (target.value === "true" ? true : (target.value === "false" ? false : null))});
        } else if (targetName === "analysisFlag") {
            const analysisReviewedBy = localStorage.getItem("username") ?? ""
            const analysisReviewedDate = (new Date().toISOString())
            
            setProfileData({...profileData,
                [targetName]: target.value,
                "analysisReviewedBy": analysisReviewedBy,
                "analysisReviewedDate": analysisReviewedDate
            })
        } else {
            setProfileData({...profileData, [targetName]: target.value});
        }
        if (submitAttempted) {
            validate(targetName, target.value);
        }
    }

    const validate = (targetName: string, targetValue: string) => {
        if (localStorage.getItem("isInternalUser") && targetName !== "fiscalYearEndDate") {
            return true;
        }

        switch (targetName) {
            case "ownerOccupiedFlag": 
                return validateOwnerOccupied(targetValue);
            case "contactName":
                return validateName(targetValue);
            case "phoneNumber":
                return validatePhoneNumber(targetValue);
            case "email":
                return validateEmail(targetValue);
            // case "fiscalYearEndDate":
            //     return validateFiscalYearEndDate(targetValue);
            case "agentFlag":
                return validateAgentFlag(targetValue);
            default:
                return true;
        }
    }

    const validateAgentFlag = (value: string) => {
        if (value !== "true" && value !== "false") {
            setAgentFlagError("Validation of agent flag failed");
            return false;
        } else {
            setAgentFlagError("");
            return true;
        }
    }
    const validateOwnerOccupied = (value: string) => {
        if (value !== "true" && value !== "false") {
            setOwnerOccupiedError(t('validation.ownerOccupiedRequired') as string);
            return false;
        } else {
            setOwnerOccupiedError("");
            return true;
        }
    }

    const validateName = (value: string) => {
        if (value === "") {
            setNameError(t('validation.nameRequired') as string);
            return false;
        } else {
            setNameError("");
            return true;
        }
    }

    const validatePhoneNumber = (value: string) => {
        const numberRegex = /^[0-9]+$/;
        if (value === "" || value.length !== 10 || !numberRegex.test(value)) {
            setPhoneError(t('validation.phoneRequired') as string);
            return false;
        } else {
            setPhoneError("");
            return true;
        }
    }

    const validateEmail = (value: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
        if (value === "" || value.length >= 100 || !emailRegex.test(value)) {
            setEmailError(t('validation.emailRequired') as string);
            return false;
        } else {
            setEmailError("");
            return true;
        }
    }

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }
        try {
            const dateValue = (date ? format(date, "yyyy-MM-dd") : "");
            setProfileData({...profileData, [name]: dateValue});
            // if (submitAttempted) {
            //     validateFiscalYearEndDate(dateValue);
            // }
        }
        catch (e) {
            console.log(e)
        }
    }

    // const validateFiscalYearEndDate = (value: string) => {
    //     if (value === "") {
    //         setFiscalYearEndDateError(t('validation.fiscalYearEndDateRequired') as string);
    //         return false;
    //     } else {
    //         setFiscalYearEndDateError("");
    //         return true;
    //     }
    // }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(resetPutProfileDetails());
        setSubmitAttempted(true);
        const data: FormData = new FormData(e.target as HTMLFormElement);
        const formValues = Object.fromEntries(data.entries()) as Record<string, unknown>;
        if (!("agentFlag" in formValues)) {
            formValues["agentFlag"] = null;
        }
        if (!("ownerOccupiedFlag" in formValues) && profileType !== "GEV") {
            formValues["ownerOccupiedFlag"] = null;
        }
        for (const key in formValues) {
            const value = formValues[key] as string;
            setIsDataValid(validate(key, value));
        }
        // dispatch(setValid(isDataValid));
        if (isDataValid) {
            dispatch(resetPutProfileDetails());
            dispatch(putProfileDetails(profileOverview.profile.profile_type_code, localStorage.getItem('currentSnapshotId') as string, profileData));
        } else {
            toast.warn(t("toastMessage.validationFailed") as string); 
            dispatch(resetSaving());
            dispatch(setNextActiveMenuItem(initialMenuItem));
        }
    }

    const buildInputRows = (profileType: string) : JSX.Element[] => {
        const tableCells = [];
        if (["HTL", "LLE", "MTL", "RHO", "GLF", "LTC"].includes(profileType)){
            tableCells.push(<td key="contact-cell-1">
                <div>
                    <label className='top-label' htmlFor='contact-trade-name'>
                    {profileType === "LLE" ? t("property") : 
                        profileType === "RHO" ? t("retirement") : 
                        profileType === "LTC" ? t("ltc")  : 
                        profileType === "GLF" ? t("golf")  : 
                        profileType === "HTL" ? t("hotel") : t("motel") }</label>
                </div>
                <div>
                    <Input name='tradeName' id='contact-trade-name' type='text' 
                    className='contact-trade-name full-width-field' value={profileData.tradeName}
                    label={profileType === "LLE" ? t("property") : 
                        profileType === "RHO" ? t("retirement")  : 
                        profileType === "LTC" ? t("ltc")  : 
                        profileType === "GLF" ? t("golf")  : 
                        profileType === "HTL" ? t("hotel") : t("motel") }/>
                </div> 
            </td>);
        }
        tableCells.push(<td key="contact-cell-2">
            <div>
                <label className='top-label' htmlFor='contact-name'><Asterisk /> {["HTL", "LLE", "RHO", "LTC", "GLF"].includes(profileType) ? 
                    t("contact")  : profileType === "MTL" ? t("manager") : t("name")}</label>
            </div>
            <div>
                <Input name='contactName' id='contact-name' type='text' className='contact-name full-width-field' 
                    label={["HTL", "LLE", "RHO", "LTC", "GLF"].includes(profileType) ? 
                    t("contact")  : profileType === "MTL" ? t("manager") : t("name")} value={profileData.contactName}
                    errorMessage={nameError} hideErrorCross={true} noTimer={true} isError={nameError !== ""} />
            </div>
        </td>);
        if (profileType === "HTL") {
            tableCells.push(<td key="contact-cell-3">
                <div>
                    <label className='top-label' htmlFor='contact-position'>{t("position")}</label>
                </div>
                <div>
                    <Input name='contactPosition' id='contact-position' type='text' className='contact-position full-width-field' 
                    label={t("position")} value={profileData.contactPosition}/>
                </div>
            </td>);
        }
        tableCells.push(<td key="contact-cell-4">
            <div>
                <label className='top-label' htmlFor='contact-phone'><Asterisk /> {t("telephone")}</label>
            </div>
            <div className='telephone-field'>
                <div className='contact-phone-container'>
                    <Input name='phoneNumber' id='contact-phone' type='text' className='contact-phone' label={t("telephone")} value={profileData.phoneNumber}
                    errorMessage={phoneError} hideErrorCross={true} noTimer={true} isError={phoneError !== ""} />
                </div>
                <div className='contact-ext-container'>
                    <Input name='phoneExtension' id='contact-ext' type='text' className='contact-ext' value={profileData.phoneExtension}/>
                </div>
            </div>
        </td>);
        tableCells.push(<td key="contact-cell-5">
            <div>
                <label className='top-label' htmlFor='contact-email'><Asterisk /> {t("email")}</label>
            </div>
            <div>
                <Input name='email' id='contact-email' type='text' className='contact-email' label={t("email")} value={profileData.email}
                errorMessage={emailError} hideErrorCross={true} noTimer={true} isError={emailError !== ""} /> 
            </div>
        </td>);
        const tableRows: JSX.Element[] = [];
        const tempCells: JSX.Element[] = [];
        tableCells.forEach((cell, index) => {
            tempCells.push(cell);
            if (tempCells.length === 3 || index === tableCells.length - 1) {
                tableRows.push(<tr className='top-aligned-row' key={`contact-row-${index}`}>
                    {tempCells.map((cell) => (cell))}
                </tr>)
                tempCells.splice(0, tempCells.length); 
            }
        });
       
        return tableRows;
    }

    return (
        <>
            <div className='contact-info-header'><p className='strong'>{t("heading") }</p></div>
            <LoadingSpinner loading={profileDetails.loading}>
                <form id="contact-form" className="contact-form" onSubmit={(e) => submit(e)} onChange={(e) => onFormChange(e)} ref={formRef}>
                    <table className='contact-info-table'>
                        <tbody>
                            {isInternalUser && <tr>
                                <td colSpan={2}/><td>
                                    <label className='contact-analysis-label' htmlFor='contact-analysis'>{t("analysis") }</label>
                                    <Dropdown id='contact-analysis' className='contact-analysis' name='analysisFlag' options={analysisOptions} value={profileData.analysisFlag ?? undefined} />
                                </td>
                            </tr>}
                            <tr>
                                <td colSpan={3}>
                                    <div className={'side-by-side contact-owner-agent-container' + (agentFlagError ? ' radio-error' : '')}>
                                        <RadioButton id='contact-owner' name='agentFlag' text={t("owner")} textPosition='right' 
                                        value="false" currentValue={profileData.agentFlag === null ? "" : profileData.agentFlag} />
                                        <RadioButton id='contact-agent' name='agentFlag' text={t("agent")} textPosition='right'
                                        value="true" currentValue={profileData.agentFlag === null ? "" : profileData.agentFlag} />
                                    </div>
                                    {agentFlagError ? <div className="input-error-message">{agentFlagError}</div> : ""}
                                </td>
                            </tr>
                            {
                                profileType !== "GEV" &&
                                <>
                            <tr>
                                <td colSpan={2}>
                                    <label><Asterisk /> {t("occupied")}</label>
                                </td>
                                <td>
                                    <span className={'side-by-side contact-owner-occupied-container' + (ownerOccupiedError ? ' radio-error' : '')}>
                                        <RadioButton id='contact-occupied-yes' name='ownerOccupiedFlag' text={t("yes")} textPosition='right'
                                        value="true" currentValue={profileData.ownerOccupiedFlag === null ? "" : profileData.ownerOccupiedFlag}  />
                                        <RadioButton id='contact-occupied-no' name='ownerOccupiedFlag' text={t("no")} textPosition='right'
                                        value="false" currentValue={profileData.ownerOccupiedFlag === null ? "" : profileData.ownerOccupiedFlag} />
                                    </span>
                                    {ownerOccupiedError ? <div className="input-error-message">{ownerOccupiedError}</div> : ""}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <label htmlFor='contact-fiscal-year-end'><Asterisk /> {t("fiscal")}</label>
                                </td>
                                <td>
                                    <DatePicker id='contact-fiscal-year-end' className='contact-fiscal-year-end' name='fiscalYearEndDate'
                                    position='bottom' value={profileData.fiscalYearEndDate} minDate={parseISO("1990-01-01")} maxDate={new Date()}
                                    errorMessage={fiscalYearEndDateError} onChange={handleDateChange}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <label htmlFor='contact-partial-year'>{t("partialYear")}</label>
                                </td>
                                <td>
                                    <Dropdown id='contact-partial-year' className='contact-partial-year' name='partialIncomeMonth' options={dropdownOptions} 
                                    value={profileData.partialIncomeMonth ?? ""} />
                                </td>
                            </tr>
                                </>
                            }
                            {buildInputRows(profileType).map((row) => (row))}
                            
                        </tbody>
                    </table>
                </form>
            </LoadingSpinner>
        </>
    );
}
